import React from 'react';
import { makeStyles } from '@material-ui/core/styles'

import Nav from './components/Nav'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#d6d7d6',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
   },
}))

export default function Default({page, background, children}) {
  const classes = useStyles();

  return (
    <div className={classes.root}
      style={{backgroundImage: `url(${background})`}}
    >
      <Nav page={page}>
      {children}
      </Nav>
    </div>
  );
}