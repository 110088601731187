import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Layout from '../layouts/Base'
import background from '../assets/images/karrabing/background.png'

const useStyles = makeStyles((theme) => ({
  group: {
    margin: '40px 0px 0px 0px',
    lineHeight: '30pt',
  },
  paragraph: {
    color: '#fff',
    '&::selection': {
      backgroundColor: '#666'
    },
  },
  text: {
    color: '#fff',
    display: 'inline',
    '&::selection': {
      backgroundColor: '#666'
    },
  },
  credit: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  root: {
    margin:'100px 200px 0px 200px',
    [theme.breakpoints.down('lg')]: {
      margin: '100px 200px 0px 200px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '100px 90px 0px 90px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '140px 30px 0px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '140px 20px 0px 20px',
    },
  },

}));

const data = [
  {
    role:'Founder and Director',
    name:'Felice Moramarco',
  },
  {
    role:'Curator',
    name:'Caterina Avataneo',
  },
  {
    role:'Research and Programming',
    name:'Sabeen Chaudhry, Dalia Maini, Lilly Markaki and Giulia Civardi',
  },
  {
    role:'Press and Communication',
    name:'Giulia Ponzano',
  },
  {
    role:'Visual Identity',
    name:'Natália Trejbalová',
  },
  {
    role:'Web Development',
    name:'Andrei Iovcev',
  },
]

const Credit = (props) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" justify="space-between" alignItems="flex-start" 
      className={classes.credit}
    >
    <Grid item>
      <Typography variant='subtitle1' className={classes.text}>{props.role}</Typography>
    </Grid>
    
    <Grid item>
      <Typography className={classes.text} variant='subtitle1'>
        {props.name === 'Andrei Iovcev' ? (
          <a 
            href="https://skllchain.com"
            rel='noreferrer noopener'
            target='_blank'
            style={{
              textDecoration:'none',
              color: '#fff'
            }}
          >{props.name}</a>
        ) : props.name}
      </Typography>
    </Grid>
    </Grid>
  );
}

export default function Page(props) {
  const classes = useStyles()

  return (
    <Layout page='about' background={background}>
      <div className={classes.root}>
        <Typography 
          align='justify' 
          className={classes.paragraph}
          variant='subtitle1'
        >
          DEMO is  a  curatorial  platform  exploring  the  aesthetic  and  political  potentialities  of  the  moving image, regularly presenting experimental films concerning subaltern conditions and speculating on alternative modes of coexistence.
        </Typography>

        <div className={classes.group}>
        {data.map((obj, i) => (
          <Credit key={i}
          role={obj.role}
          name={obj.name}
          />
        ))}
        </div>

      </div>
    </Layout>
  )
}
